@import url('https://fonts.cdnfonts.com/css/garbata-trial');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;1,500&display=swap');
body {
  margin: 0;
  font-family: 'Garbata Trial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(208, 208, 208);
}

code {
  font-family: 'Garbata Trial', sans-serif;
}

.marginTop30{
  margin-top: 30px;
}